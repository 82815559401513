import "./App.css";
import KanBanBoard from "./components/KanBanBoard";

function App() {
  return (
    <body>
      <KanBanBoard />
    </body>
  );
}

export default App;
